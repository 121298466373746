import {useEffect, useContext, useState, useRef} from 'react';
import GameContext  from '../contexts/game-context';
import {useHistory} from 'react-router-dom';

const Gameroom = () => {
    const {gameChannel, playerId, playerList, setPlayerList} = useContext(GameContext);
    const [answerList, setAnswerList] = useState([]);
    const [timer, setTimer] = useState();
    const [maxTimer, setMaxTimer] = useState(3);
    const [ready, setReady] = useState(false);
    const [round, setRound] = useState(1);
    const [gameState, setGameState] = useState("Get Ready!");
    const [maxRounds, setMaxRounds] = useState(60)
    const [hint, setHint] = useState("");
    const audio = useRef();
    const currentMovie = useRef();
    const winner = useRef();
    const history = useHistory();
    const inputRef = useRef(null);

    // subscribes to the answer list
    useEffect(() => {
        if (inputRef) inputRef.current.focus();
        gameChannel.subscribe('player-list', (msg) => {
            setPlayerList(msg.data.playerList)
        })
        gameChannel.subscribe('answer-list', (msg) => {
            setAnswerList(msg.data.answerList);
        });

        gameChannel.subscribe('max-rounds', (msg) => {
            setMaxRounds(msg.data.maxRounds);
        })

        gameChannel.subscribe('game-tick', (msg) => {
            setTimer(msg.data.gameTick);
        });

        gameChannel.subscribe('hint1', (msg) => {
            console.log(msg.data.hint);
            setHint(msg.data.hint);
        });

        gameChannel.subscribe('hint2', (msg) => {
            setHint(msg.data.hint);
        });

        gameChannel.subscribe('movie-send', (msg) => {
            audio.current = new Audio(msg.data.movie.Link);
            audio.current.play();
            currentMovie.current = msg.data.movie;
            setGameState('Guess the sound...')
            setMaxTimer(30);
            setReady(true);
        });

        gameChannel.subscribe('round-win', (msg) => {
            setReady(false);
            setMaxTimer(5);
            winner.current = msg.data.winner;
            setGameState(`${winner.current} got it right! The answer was "${currentMovie.current.Title}". Preparing next round...`)
            audio.current.pause();
        })

        gameChannel.subscribe('round-end', (msg) => {
            winner.current = null;
            setRound(msg.data.round);
            setGameState("Get Ready!");
            setMaxTimer(3);
            setHint("");
        });

        gameChannel.subscribe('game-over', (msg) => {
            audio.current = null;
            history.push('/endRoom');
        });

        gameChannel.subscribe('reset-game', (msg) => {
            if (audio.current) {
                audio.current.pause();
            }
            audio.current = null;
            history.push('/ready');    
        });

        return () => {
            gameChannel.unsubscribe();
        }

    }, [gameChannel, setAnswerList]);

    const onAnswerSubmit = (e) => {
        e.preventDefault();
        gameChannel.publish('player-answer', {player : playerId, answer : e.target.elements.answer.value});
        document.getElementById('answer-submit-form').reset();
    }

    const backToLobby = () => {
        gameChannel.publish('reset-game', {});
        if (audio.current) {
            audio.current.pause();
        }
        audio.current = null;
        history.push('/ready');
    }

    return (
        <section class="section">
            <div class="level">
                <h1 class="level-item has-text-centered title is-1">{`Round ${round}/${maxRounds}`}</h1>
            </div>
            <div class="content is-large my-0">
                <p class="has-text-centered my-0">{gameState}</p>
            </div>
            <div class="content">
                <p class="has-text-centered my-0">{`-${timer ? timer : ''}/${maxTimer}-`}</p>
            </div>
            {hint && <div style={{ whiteSpace: 'pre-wrap' }} class="has-text-centered content">
                <p>{"hint: " + hint}</p>
            </div>}
            <div class="content">
                {playerList.map((player, i) => 
                    <span key={i} class="level-item has-text-centered icon-text">
                        <span class="icon">
                            <i class="fas fa-user fa-xs"></i>
                        </span>   
                        <span key={player.id}>{`${player.name}: ${player.score}`}</span>
                    </span>
                )}
            </div>
            <div class="content">
                {answerList.map((answer, i) => 
                    <span key={i} class="level-item has-text-centered icon-text">
                        <span class="icon">
                            <i class="fas fa-comment fa-xs"></i>
                        </span>   
                        <span>{`${answer.player} : ${answer.answer}`}</span>
                    </span>
                )}
            </div>
            <div class="is-flex is-justify-content-center">
                <form id="answer-submit-form" onSubmit={onAnswerSubmit}>
                    <div class="field has-addons">
                        <div class="control">
                            <input class="input" ref={inputRef} type="text" name="answer"/>
                        </div>
                        <div class="control">
                            <button class="button mx-2" disabled={!ready}>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="is-flex is-justify-content-center">
                    <button class="button is-danger my-4" onClick={backToLobby}>End Game</button>
            </div>
        </section>
    )
}

export default Gameroom;