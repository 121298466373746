import {useContext, useEffect, useState} from 'react';
import GameContext from '../contexts/game-context';
import {useHistory} from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Component for waiting room - has a button to start and the list of currently joined players
const Room = () => {
    const {gameChannel, roomID, playerList, setPlayerList} = useContext(GameContext);
    const history = useHistory();
    const [collections, setCollections] = useState([]);

    useEffect( () => {
        async function fetchCollection() {
            const resp = await fetch('/allCollections');
            const data = await resp.json();
            setCollections(data.collections);
        }
        fetchCollection();
    }, []);

    useEffect( () => {
        if (gameChannel) {
            gameChannel.subscribe('player-list', (msg) => {
                setPlayerList(msg.data.playerList);
            });
    
            gameChannel.subscribe('game-start', (msg) => {
                history.push('/gameroom');
            });
        }
    }, [history, gameChannel, setPlayerList])

    const startGame = (edition) => {
        gameChannel.publish('game-start', {edition});
        history.push('/gameroom');
    }

    const leaveGame = () => {
        gameChannel.presence.leave();
        gameChannel.detach();
        history.push('/');
    }

    return (
        <section class="section">
            <div class="level">
                <h1 class="level-item has-text-centered title is-1">Waiting for Players...</h1>
            </div>
            <div class="level">
                <h4 class="level-item has-text-centered title is-4">Room ID: {roomID}</h4>
            </div>
            <div class="content is-large">
                {playerList.map((player, i) => 
                    <span key={i} class="level-item has-text-centered icon-text">
                        <span class="icon">
                            <i class="fas fa-user fa-xs"></i>
                        </span>   
                        <span key={player.id}>{player.name}</span>
                    </span>
                )}
            </div>
            <p class="level-item has-text-centered mb-3">When ready, anyone can press Start Game to begin. You cannot start if a game is already in progress.</p>
            <div class="is-flex is-justify-content-center is-flex-direction-column">
                {collections && collections.map((collection) => {
                        return <button key={collection.Id} class="mx-auto button mx-2 my-2" onClick={() => startGame(collection.Id)}>{collection.Name}</button>
                })
                }
                <button class="mx-auto button mx-2 my-2 is-danger" onClick={leaveGame}>Leave Game</button>
            </div>
        </section>
    )
}

export default Room;