
import {useHistory} from 'react-router-dom';

function Collection({collection, setCollections}) {
    const history = useHistory();
    const deleteCollection = async () => {
        const resp = await fetch(`collection/${collection.Id}`, {method : "DELETE"});
        const data = await resp.json();
        setCollections(data.collections);
    }

    const setCurrentCollection = () => {
        history.push(`collections/${collection.Name}/${collection.Id}`)
    }

    return (
        <div class="box">
            <div class="level">
                <div class="level-left">
                    <div onClick={setCurrentCollection} class="is-clickable level-item">
                        {collection.Name}
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        {collection.IsDeletable && <a onClick={deleteCollection} class="icon has-text-danger">
                            <i class="fas fa-trash"></i>
                        </a>}
                    </div>
                </div>
            </div>        
        </div>
    )
}

export default Collection;