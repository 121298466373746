import {useState, useEffect} from 'react';
import Collection from './Collection';

const ViewCollections = () => {
    const [collections, setCollections] = useState([]);
    const [isInserting, setIsInserting] = useState(false);

    useEffect( () => {
        async function fetchCollection() {
            const resp = await fetch('/allCollections');
            const data = await resp.json();
            setCollections(data.collections);
        }
        fetchCollection();
    }, []);

    const insertCollection = () => {
        setIsInserting(true);
    }

    const closeModal = () => {
        setIsInserting(false)
    }

    const submitCollection = async (e) => {
        e.preventDefault();
        const resp = await fetch("/collection", {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body : JSON.stringify({name : e.target.elements.name.value})
        });
        setIsInserting(false);
        const data = await resp.json();
        setCollections(data.collections);
    }

    return (
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <section class="m-6">
                    <h1 class="level-item has-text-centered title is-1">Manage Collections</h1>
                </section>
                <div class="block">
                    {collections && collections.map(collection => <Collection key={collection.Id} collection={collection} setCollections={setCollections} />)}
                </div>
                <div class="level">
                    <div class="level-item">
                        <button onClick={insertCollection} class="button is-primary">Add Collection</button>
                    </div>
                </div>
            </div>
            <div class={`${isInserting ? "is-active" : ""} modal`}>
                <div onClick={closeModal} class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <form class="m-4" onSubmit={submitCollection}>
                            <h3 class="title">Add a new collection</h3>
                            <p class="mb-5">Pick a name for your collection. You will be able to add sounds to play from your collection for the game.</p>
                            <div class="field">
                                <label class="label">Collection Name</label>
                                <div class="control">
                                    <input class="input" type="text" name="name" placeholder="Collection Name Here"/>
                                </div>
                            </div>
                            <button class="button">Submit</button>
                        </form>
                    </div>
                </div>
                <button onClick={closeModal} class="modal-close is-large" aria-label="close"></button>
            </div>
        </div>
        
    )
}

export default ViewCollections;