import {useState, useEffect} from 'react';
import * as Ably from 'ably';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Gamecontext from './contexts/game-context';
import Login from './components/Login';
import Room from './components/Room';
import Gameroom from './components/Gameroom';
import Endroom from './components/Endroom';
import ViewCollections from './components/ViewCollections';
import ViewSounds from './components/ViewSounds';
import 'bulma/css/bulma.min.css';

function App() {
  const [realtime, setRealtime] = useState(null);
  const [gameChannel, setGameChannel] = useState(null);
  const [playerId, setPlayerId] = useState('');
  const [playerList, setPlayerList] = useState([]);
  const [roomID, setRoomID] = useState("");

  // authorize the socket connection
  useEffect(() => {
    setRealtime(new Ably.Realtime( {authUrl: "/auth"}));
  }, []);

  useEffect( () => {
    if (realtime) {
      realtime.connection.once('connected', () => {
        setPlayerId(realtime.auth.clientId);
      });
    }
  }, [realtime]);

  return (
    <Gamecontext.Provider value={{gameChannel,roomID, playerId, playerList, setPlayerList, setGameChannel, setRoomID}}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true}>
            <Login realtime={realtime} />
          </Route>
          <Route path="/ready">
            <Room />
          </Route>
          <Route path="/gameroom">
            <Gameroom/>
          </Route>
          <Route path="/endroom">
            <Endroom/>
          </Route>
          <Route path="/collections/:collectionName/:collectionId">
            <ViewSounds/>
          </Route>
          <Route path="/collections">
            <ViewCollections/>
          </Route>
        </Switch>
      </BrowserRouter>
    </Gamecontext.Provider>
  );
}

export default App;
