import {useContext, useEffect, useState} from 'react';
import GameContext  from '../contexts/game-context';
import {useHistory} from 'react-router-dom';
import 'bulma/css/bulma.min.css';

const Login = ({realtime}) => {
    const { playerId, setGameChannel, setRoomID} = useContext(GameContext);
    const [isJoining, setIsJoining] = useState(true);
    const [errorText, setErrorText] = useState("");
    const history = useHistory();

    const submitJoin = async function(e) {
        e.preventDefault();
        const roomID = e.target.elements.room.value.toUpperCase();
        const resp = await fetch("/join", {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body : JSON.stringify({roomID})
        });
        const data = await resp.json();
        if (!data.exists) {
            setErrorText(data.error);
            return;
        }

        await setUpChannel(roomID, e.target.elements.name.value);
        history.push("/ready");
    }

    const submitCreate = async function(e) {
        e.preventDefault();
        const resp = await fetch("/create", {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
        const data = await resp.json();
        await setUpChannel(data.roomID, e.target.elements.name.value);
        history.push("/ready");
    }

    const setUpChannel = async function(roomID, name) {
        const channel = realtime.channels.get("game-channel-" + roomID);
        setRoomID(roomID);
        setGameChannel(channel);
        await channel.presence.enter({name})
    }

    const goToCollections = async function () {
        history.push("/collections");
    }

    return (
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <div class="level m-6">
                    <h1 class="level-item has-text-centered title is-1">Guess The Movie</h1>
                </div>
                <div class="content">
                    <p class="subtitle is-4">Rules:</p>
                    <ul>
                        <li>A sound clip from a movie will play. Your goal is to guess which movie the clip is from faster than the other people in the game.</li>
                        <li>The game will end after 60 rounds. Whoever has guessed the most movies wins the game.</li>
                        <li>Your guess should be all lowercase letters (ex. The Godfather = the godfather)</li>
                        <li>Your guess should not contain apostrophes, colons, commas, or other punctuations (ex. Shazam! = shazam)</li>
                        <li>Ignore hyphens (ex. Spider-man = spiderman)</li>
                        <li>If the movie is part of a series, do not include the series name. (ex. The Lord of the Rings: Fellowship of the Ring = "fellowship of the ring")</li>
                    </ul>
                </div>
                <nav class="panel p-3">
                    <p class="panel-tabs">
                        <a id="joingame" class={`${isJoining ? "is-active" : ""} is-size-6`} onClick={() => setIsJoining(true)}>Join Game</a>
                        <a id="creategame" class={`${!isJoining ? "is-active" : ""} is-size-6`} onClick={() => setIsJoining(false)}>Host Game</a>
                    </p>
                    { isJoining ? <form class="m-4" onSubmit={submitJoin}>
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <input class="input" type="text" name="name" placeholder="Your name here"/>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Room Code</label>
                            <div class="control">
                                <input class="input" type="text" name="room" placeholder="Room code"/>
                            </div>
                        </div>
                        <button class="button is-primary is-fullwidth" disabled={!playerId}>Join</button>
                        <p class="has-text-danger">{errorText}</p>
                    </form> : 
                    <form class="m-4" onSubmit={submitCreate}>
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <input class="input" type="text" name="name" placeholder="Your name here"/>
                            </div>
                        </div>
                        <button class="button is-primary is-fullwidth" disabled={!playerId}>Create</button>
                    </form> 
                    }
                </nav>
                <button class="button is-link is-fullwidth" onClick={goToCollections}>Manage Collections</button>
            </div>
        </div>
    );
}

export default Login;