import { useParams } from "react-router-dom";
import {useEffect, useState, useRef} from "react";
import Sound from "./Sound";

function ViewSounds() {
    const {collectionId, collectionName} = useParams();
    const [sounds, setSounds] = useState([]);
    const [isInserting, setIsInserting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [titleErrorText, setTitleErrorText] = useState("");

    const form = useRef(null);
    const titleInput = useRef(null);

    useEffect( () => {
        async function fetchSounds() {
            const resp = await fetch(`/movies/${collectionId}`);
            const data = await resp.json();
            setSounds(data.sounds);
        }   
        fetchSounds();
    }, []);

    const insertSound = () => {
        setIsInserting(true);
        setTimeout(() => {
            titleInput.current.focus();
        }, 100); 
    }

    const closeModal = () => {
        if (isLoading) return;
        setIsInserting(false);
        form.current.reset();
        setErrorText("");
    }

    const hasDuplicates = (title) => {
        const filter = sounds.filter(sound => sound.Title == title);
        console.log(filter);
        return filter.length > 0;
    }
    const submitSound = async (e) => {
        e.preventDefault();

        const title = e.target.elements.title.value;
        const youtubeLink = e.target.elements.youtubeLink.value;
        const timestamp = e.target.elements.timestamp.value;

        if (!title || !youtubeLink || !timestamp) {
            setErrorText("Provide All Inputs");
            return;
        }

        if (hasDuplicates(title)) {
            setErrorText("Duplicate Title");
            return;
        }

        setIsLoading(true);
        setErrorText("");

        const resp = await fetch("/insertSound", {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body : JSON.stringify({
                title,
                youtubeLink,
                timestamp,
                collectionName : collectionName,
                collectionId : collectionId
            })
        });
        const data = await resp.json();
        if (data.error) {
            setTitleErrorText(data.error);
        }

        setSounds(data.sounds);
        setIsLoading(false);
        closeModal();
    }
    return (
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <section class="m-6">
                    <h1 class="level-item has-text-centered title is-1">{collectionName}</h1>
                </section>
                <div class="content">
                    <p class="subtitle is-4">Adhere by these rules:</p>
                    <ul>
                        <li>Title should be all lowercase letters (ex. The Godfather = the godfather)</li>
                        <li>Title should not contain apostrophes, colons, commas, or other punctuations (ex. Shazam! = shazam)</li>
                        <li>Ignore hyphens (ex. Spider-man = spiderman)</li>
                        <li>If the movie is part of a series, do not include the series name. (ex. The Lord of the Rings: Fellowship of the Ring = "fellowship of the ring")</li>
                        <li>Max 30 sounds per collection</li>
                    </ul>
                </div>
                {sounds && sounds.map((sound,i) => <Sound key={sound.Id} count={i} sound={sound} setSounds={setSounds}/>)}
                <p class="subtitle is-5 has-text-centered has-text-danger">{titleErrorText}</p>
                <div class="level">
                    <div class="level-item">
                        <button onClick={insertSound} disabled={sounds.length >= 30} class="button is-primary">Add Sound</button>
                    </div>
                </div>
            </div>
            <div class={`${isInserting ? "is-active" : ""} modal`}>
                <div onClick={closeModal} class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <form ref={form} class="m-4" onSubmit={submitSound}>
                            <h3 class="title">Add a sound here using Youtube</h3>
                            <p class="mb-5">Provide the youtube link and the timestamp. It will automatically take 30 seconds from the timestamp provided</p>
                            <div class="field">
                                <label class="label">Sound Name</label>
                                <div class="control">
                                    <input ref={titleInput} class="input" type="text" name="title" placeholder="This is going to be the answer"/>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Youtube Link</label>
                                <div class="control">
                                    <input class="input" type="text" name="youtubeLink" placeholder="youtube.com/watch?{something}"/>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Timestamp</label>
                                <div class="control">
                                    <input class="input" type="text" name="timestamp" defaultValue={0} placeholder="Should be a number in seconds or in the form mm:ss (ex. 1:30 or 90)"/>
                                </div>
                            </div>
                            <button disabled={isLoading || sounds.length>=30} class="button is-primary">{isLoading ? "Loading" : "Submit"}</button>
                            <p class="has-text-danger">{errorText}</p>
                        </form>
                    </div>
                </div>
                <button onClick={closeModal} class="modal-close is-large" aria-label="close"></button>
            </div>
        </div>
    )
}

export default ViewSounds;