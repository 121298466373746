import {useContext, useEffect} from 'react';
import GameContext  from '../contexts/game-context';
import {useHistory} from 'react-router-dom';


const Endroom = () => {
    const {gameChannel, playerList} = useContext(GameContext)
    const history = useHistory();

    useEffect(() => {
        gameChannel.subscribe('reset-game', () => {
            history.push('/ready');
        })
    })

    const returnToLobby = () => {
        gameChannel.publish('reset-game', {});
        history.push('/ready');
    }
    return (
        <section class="section">
            <h1 class="level-item has-text-centered title is-1">{`${playerList[0].name} won the game!`}</h1>
            <div class="content">
                {playerList.map((player, i) => 
                    <span key={i} class="level-item has-text-centered icon-text">
                        <span class="icon">
                            <i class="fas fa-user fa-xs"></i>
                        </span>   
                        <span key={player.id}>{`${player.name}: ${player.score}`}</span>
                    </span>
                )}
            </div>
            <div class="is-flex is-justify-content-center">
                <button class="button" onClick={returnToLobby}>Return to Lobby</button>
            </div>
        </section>
    )
}

export default Endroom;
