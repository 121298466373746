import {useRef, useState} from 'react';

function Sound({sound, setSounds, count}) {
    const [isEditing, setIsEditing] = useState(false);
    const titleInput = useRef(null);
    const deleteSound = async () => {
        const resp = await fetch(`/movies/${sound.Id}`, {method: "DELETE"});
        const data = await resp.json();
        setSounds(data.sounds);
    }

    const editSound = async () => {
        setIsEditing(true);
        setTimeout(() => {
            titleInput.current.focus();
        }, 50); 
    }

    const closeModal = async () => {
        setIsEditing(false);
    }

    const submitSoundChange = async (e) => {
        e.preventDefault();
        const resp = await fetch(`/movies/${sound.Id}`, {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({title: e.target.elements.title.value})
        });
        const data = await resp.json();
        setSounds(data.sounds);
        setIsEditing(false);
    }

    return (
        <div>
            <div class="box">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <p>{count+1}. {sound.Title}</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <a onClick={editSound} class="icon has-text-info">
                                <i class="fas fa-edit"></i>
                            </a>
                            <a onClick={deleteSound} class="icon has-text-danger">
                                <i class="fas fa-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="level">
                    <div class="level-left">
                        <audio src={`${sound.Link}`} class="audio-main" id={sound.Id} controls></audio>   
                    </div>
                </div>
            </div>
            <div class={`${isEditing ? "is-active" : ""} modal`}>
                <div onClick={closeModal} class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <form class="m-4" onSubmit={submitSoundChange}>
                            <h3 class="title">{`Editing sound for: ${sound.Title}`}</h3>
                            <div class="field">
                                <label class="label">Sound Title</label>
                                <div class="control">
                                    <input ref={titleInput} class="input" type="text" name="title" defaultValue={`${sound.Title}`} placeholder="Sound Title Here"/>
                                </div>
                            </div>
                        <button class="button">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    
    )
}

export default Sound;